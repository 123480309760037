<template>
  <div style="width: 100%; position: relative">
    <div class="common-gaine" style="width: 600px">
      <div class="common-plate-title">
        <span>{{ $trans('选择收货地址', '选择收货地址') }}</span>
        <i
          class="iconfont icon-fanhui1"
          :title="$trans('返回上一页', '返回上一页')"
          @click="$router.history.go(-1)"
        ></i>
      </div>
      <div
        class="empty-address flex-column-cen"
        @click="enterPageHandle"
        v-if="addressList && addressList.length < 1"
      >
        <i class="iconfont icon-shouhuodizhi1"></i>
      </div>
      <div class="has-address" v-else>
        <div class="hr"></div>
        <Address
          @on="getAddressListHandle"
          class="address-item-box"
          v-for="item in addressList"
          :key="item.id"
          :edit="true"
          :data="item"
        />
      </div>
      <div class="btn-fill" @click="enterPageHandle">
        {{ $trans('新建地址', '新建地址') }}
      </div>
    </div>
  </div>
</template>
<script>
import Address from '@/components/address/Address.vue'
import { getAddressList } from '@/util/js/api.js'
export default {
  name: '',
  data() {
    return {
      addressList: [], // 地址列表
    }
  },
  mounted() {
    this.getAddressListHandle()
  },
  components: {
    Address,
  },
  methods: {
    // 获取地址列表
    getAddressListHandle() {
      getAddressList({ userId: this.$store.state.userId })
        .then((res) => {
          this.addressList = res.data
            ? JSON.parse(JSON.stringify(res.data))
            : []
        })
        .catch(() => {
          this.$message.error(
            this.$trans('网络错误，请稍后再试', '网络错误，请稍后再试')
          )
        })
    },
    // 新建地址
    enterPageHandle() {
      this.$router.push({
        path: '/address/new',
        query: {
          ...this.$route.query,
          editPath: this.$route.path,
          type: 'add',
        },
      })
    },
  },
}
</script>
<style scoped>
.empty-address {
  height: 400px;
  justify-content: center;
}
.icon-shouhuodizhi1 {
  font-size: 70px;
  cursor: pointer;
}
.empty-address .empty-address-tip {
  margin-top: 20px;
  color: #444444;
  font-family: 'myCoolFontRegular';
  font-size: 16px;
}
.hr {
  position: relative;
  height: 1px;
  background: rgba(17, 17, 17, 0.1);
  margin-top: 22px;
}
.btn-fill {
  cursor: pointer;
  width: 600px;
  margin: 40px auto 0;
}
.address-item-box {
  padding: 30px;
}
</style>
